export function showError(message) {
    const toast = useToast()
    toast.add({
        title: message,
        icon: 'i-heroicons-exclamation-circle',
        ui: {
            icon: {
                color: 'text-pink dark:text-pink',
            },
        }
    })
}

export function showSuccess(message) {
    const toast = useToast()
    toast.add({ title: message })
}
